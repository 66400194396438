import React, { useCallback, useEffect, useMemo } from "react";
import { useGetCreditCardsQuery, useGetSepaQuery } from "../../../../store/api/user";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Popover,
	Radio,
	Skeleton,
	styled,
	Typography,
} from "@mui/material";
import InexCard from "../../../InexCard";
import moment from "moment";
import ErrorOutlineIcon from "@mui/icons-material/Error";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../store/reducers/authSlice";
import { ReactComponent as Check } from "../../../../resources/images/svg/checks.svg";
import {
	useGetCurrentSubscriptionsQuery,
	useLazyCreateSepaQuery,
	useUpdateSubscriptionMutation,
} from "../../../../store/api/subscriptions";
import { SUBSCRIPTIONS_TYPES } from "../../../../routes/Abonnement";
import { useGetUserAddressQuery } from "../../../../store/api/address";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../../../../store/reducers/snackbarSlice";
import InexButton from "../../../InexButton";
import { EditRounded } from "@mui/icons-material";
export const NOT_RENEW_INDEX = -1;

export const PaiementMethodTitle = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Bold",
		color: theme.palette.secondary.main,
		fontSize: 18,
	};
});

export const FieldText = styled(Typography)(({ theme }) => {
	return {
		color: "rgba(24,43,67,0.5)",
		fontFamily: "Branding Medium",
		fontSize: 12,
		fontWeight: "500",
	};
});

export const PaiementMethodContent = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Medium",
		color: theme.palette.secondary.main,
		fontSize: 18,
		fontWeight: "600",
		display: "flex",
	};
});

const DotStyle = styled(Typography)(({ theme }) => {
	return {
		height: 5,
		width: 5,
		backgroundColor: theme.palette.secondary.main,
		borderRadius: 5,
		marginRight: 2,
	};
});

export const TopBorder = styled(Box)(({ theme, selected }) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		borderTop: `10px solid ${theme.palette.secondary.main}`,
		pointerEvents: "none",
		zIndex: 1,
		opacity: selected === true ? 1 : 0,
		transition: "opacity 0.3s",
	};
});

export const Dot = ({ nb }) => {
	return (
		<Box sx={{ display: "flex" }}>
			{Array.from(Array(nb)).map((_, index) => (
				<DotStyle componant={"span"} key={index} />
			))}
		</Box>
	);
};

export const ActionBox = styled(Box)(({ theme, selected }) => {
	return {
		display: "flex",
		paddingTop: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(1.5),
		alignItems: "center",
		gap: theme.spacing(2),
		cursor: "pointer",
		transition: "background 0.3s",
		"&:hover": {
			backgroundColor: "rgba(0,0,0,0.09)",
		},
	};
});

const PaiementActions = ({ selectedPaiementMethod, closePopover }) => {
	const { data: { newabos } = {}, refetch: refetchCurrentSubscription } = useGetCurrentSubscriptionsQuery({});
	const [updateSubscription] = useUpdateSubscriptionMutation();
	const { data: { adresse } = {} } = useGetUserAddressQuery({ idadresse: newabos?.idadresse }, { skip: !newabos });
	const dispatch = useDispatch();
	const [isModalConfirmOpen, setModalConfirmOpen] = React.useState(false);

	const setShowSnackBar = () => {
		dispatch(setShowSnackbar(true));
		dispatch(setSnackbarSeverity("success"));
		dispatch(setSnackbarText("Vos informations ont bien été mises à jour."));
	};

	const _updateSubscription = (subscriptionType, frequence) => {
		return new Promise((resolve, reject) => {
			updateSubscription({
				mag: +(subscriptionType === SUBSCRIPTIONS_TYPES.Mag),
				digital: +(subscriptionType === SUBSCRIPTIONS_TYPES.Digital),
				tv: +(subscriptionType === SUBSCRIPTIONS_TYPES.Tv),
				zone: adresse?.zone,
				frequence,
				idadresse: adresse?.idadresse,
				typerenouv: selectedPaiementMethod?.idcb ? 2 : 3,
				idcb: selectedPaiementMethod?.idcb,
				idiban: selectedPaiementMethod?.idsepa,
			})
				.then((data) => resolve())
				.catch(reject);
		});
	};

	const applyToAllSubscriptions = () => {
		const promises = [];
		if (!!newabos.digital) {
			promises.push(_updateSubscription(SUBSCRIPTIONS_TYPES.Digital, newabos?.digital?.frequence));
		}
		if (!!newabos.tv) {
			promises.push(_updateSubscription(SUBSCRIPTIONS_TYPES.Tv, newabos?.tv?.frequence));
		}
		if (!!newabos.mag) {
			promises.push(_updateSubscription(SUBSCRIPTIONS_TYPES.Mag, newabos?.mag?.frequence));
		}
		Promise.all(promises).then(() => {
			closePopover();
			refetchCurrentSubscription();
			setShowSnackBar();
		});
	};

	const handleModalClose = () => {
		setModalConfirmOpen(false);
		closePopover();
	};

	const openModal = () => {
		setModalConfirmOpen(true);
	};

	const _updateCB = () => {
		window.open(
			`https://secure2.inrees.com/desabonnement.php?ce=${selectedPaiementMethod?.idcb}&ei=${selectedPaiementMethod?.iduser}`,
			"_blank",
		);
		handleModalClose();
		closePopover();
	};

	return (
		<React.Fragment>
			<Dialog fullWidth open={isModalConfirmOpen} onClose={handleModalClose}>
				<DialogTitle color={"secondary"} fontFamily={"Branding Bold"}>
					Souhaitez-vous modifier votre carte bancaire ?
				</DialogTitle>
				<DialogContent>
					<Typography
						sx={{
							fontFamily: "Branding Medium",
							color: (theme) => theme.palette.secondary.main,
							fontSize: 15,
						}}
					>
						Pour vérifier votre moyen de paiement et conformément aux normes de sécurité, les banques
						prélèvent et recréditent un montant de 1€ sur votre compte.{" "}
					</Typography>
				</DialogContent>
				<DialogActions>
					<InexButton
						onClick={handleModalClose}
						variant={"text"}
						textWithGradient={false}
						text={"Annuler"}
						textSx={{
							color: (theme) => theme.palette.secondary.main,
						}}
						sx={{
							width: 100,
							background: "transparent",
							"&:hover": {
								borderColor: (theme) => theme.palette.secondary.main,
								background: (theme) => theme.palette.grey[300],
							},
						}}
					/>
					<InexButton
						onClick={_updateCB}
						disabled={false}
						variant={"text"}
						textWithGradient={false}
						text={"Valider"}
						textSx={{
							color: "white",
						}}
						sx={{
							width: 100,
						}}
					/>
				</DialogActions>
			</Dialog>
			<div>
				{selectedPaiementMethod.idcb && (
					<ActionBox onClick={openModal}>
						<Check />
						<Typography color={(theme) => theme.palette.secondary.main}>
							Modifier la carte bancaire
						</Typography>
					</ActionBox>
				)}
				{/*<ActionBox onClick={applyToAllSubscriptions}>
					<Check />
					<Typography color={(theme) => theme.palette.secondary.main}>
						Appliquer ce moyen de paiement à tous mes abonnements
					</Typography>
				</ActionBox>*/}
			</div>
		</React.Fragment>
	);
};

const PaiementMethod = ({ infos, subscription, index, selectedIndex, setSelectedIndex }) => {
	const user = useSelector(getUser);
	const [anchorEl, setAnchorEl] = React.useState(null);

	// const isPaiementMethodSelected = useMemo(() => {
	// 	if (subscription.typerenouv === 3 && !infos.datefin) return true;
	// 	return subscription.typerenouv === 2 && infos?.idcb === parseInt(subscription.cb);
	// }, [infos, subscription]);

	const isPaiementMethodSelected = useMemo(() => {
		return index === selectedIndex;
	}, [index, selectedIndex]);

	React.useEffect(() => {
		if (subscription.typerenouv === 3 && !infos.datefin) {
			setSelectedIndex(index);
		}
		//if (subscription.typerenouv === 2 && infos?.idcb === parseInt(subscription.cb)) {
		if (subscription.typerenouv === 2 && infos.idcb !== undefined) {
			setSelectedIndex(index);
		}
	}, []);

	const handleClick = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (!_isSepa) setAnchorEl(event.currentTarget);
	};

	const closePopover = () => {
		setAnchorEl(null);
	};

	const handleClose = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const _isSepa = useMemo(() => {
		return !infos.datefin;
	}, [infos]);

	const _expDate = useMemo(() => {
		if (!infos.datefin) return null;
		let [month, year] = [infos.datefin.slice(0, 2), infos.datefin.slice(2)];
		year = `20${year}`;
		return {
			month,
			year,
		};
	}, [infos]);

	const is_card_valid = useMemo(() => {
		if (!_expDate) return true;
		const currentYear = moment().year();
		const currentMonth = moment().month();
		if (parseInt(_expDate.year, 10) < currentYear) return false;
		if (parseInt(_expDate.year, 10) === currentYear && parseInt(_expDate.month, 10) < currentMonth) return false;
		return true;
	}, [_expDate]);

	const _onRadioPressed = useCallback(() => {
		setSelectedIndex(index);
	}, [index]);

	return (
		<Grid item xs={12}>
			<InexCard
				onClick={_onRadioPressed}
				selected={isPaiementMethodSelected}
				sx={{
					padding: (theme) => theme.spacing(2),
					paddingTop: (theme) => theme.spacing(2),
					paddingBottom: (theme) => theme.spacing(2),
					paddingLeft: 0,
					backgroundColor: !is_card_valid && "#FAE7EC",
					"&:hover": {
						cursor: "pointer",
					},
				}}
			>
				<TopBorder selected={isPaiementMethodSelected} />
				{!is_card_valid && (
					<Box sx={{ display: "flex", marginLeft: (theme) => theme.spacing(5) }}>
						<ErrorOutlineIcon sx={{ fontSize: 25, color: "#F07892" }} />
						<Typography sx={{ color: "#F07892", marginLeft: (theme) => theme.spacing(1) }}>
							Attention ce mode de paiement n’est plus valide.
						</Typography>
					</Box>
				)}
				<Grid
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
					}}
					item
					xs={12}
				>
					<Box display={"flex"} alignItems={"center"} ml={5}>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							disableAutoFocus={true}
							disableEnforceFocus={true}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							PaperProps={{
								style: {
									borderRadius: 8,
								},
							}}
						>
							<PaiementActions selectedPaiementMethod={infos} closePopover={closePopover} />
						</Popover>
						<PaiementMethodTitle>{_isSepa ? "Prélèvement SEPA" : "Carte bancaire"}</PaiementMethodTitle>
						{!_isSepa && (
							<IconButton
								onClick={handleClick}
								sx={{
									marginLeft: 1,
								}}
							>
								<EditRounded color={"secondary"} />
							</IconButton>
						)}
					</Box>
					<Radio
						checked={isPaiementMethodSelected}
						onChange={_onRadioPressed}
						value="a"
						name="radio-buttons"
						inputProps={{ "aria-label": "A" }}
						sx={{
							color: (theme) => theme.palette.secondary.main,
							"&.Mui-checked": {
								color: (theme) => theme.palette.secondary.main,
							},
						}}
					/>
				</Grid>
				<Grid
					container
					sx={{
						display: "flex",
						alignItems: "center",
						marginLeft: (theme) => theme.spacing(5),
						marginTop: (theme) => theme.spacing(1),
					}}
				>
					{!_isSepa ? (
						<>
							<Grid item xs={4}>
								<FieldText>Numéro</FieldText>
								<PaiementMethodContent component={"span"}>
									<Typography component={"span"} sx={{ display: "flex", alignItems: "center" }}>
										<Dot nb={4} />
										&nbsp;-&nbsp;
										<Dot nb={2} />
										{infos.cb}
									</Typography>
								</PaiementMethodContent>
							</Grid>
							<Grid item xs={4}>
								<FieldText>Date d'expiration</FieldText>
								<PaiementMethodContent component={"span"}>
									<Typography
										sx={{ display: "flex", alignItems: "center" }}
										color={!is_card_valid && "#F07892"}
									>
										{`${_expDate?.month}/${_expDate?.year}`}
									</Typography>
								</PaiementMethodContent>
							</Grid>
						</>
					) : (
						<Grid item xs={4}>
							<FieldText>Titulaire</FieldText>
							<PaiementMethodContent component={"span"}>
								<Typography sx={{ display: "flex", alignItems: "center" }}>
									{`${user.prenom.capitalize()} ${user.nom.toUpperCase()}`}
								</Typography>
							</PaiementMethodContent>
						</Grid>
					)}
				</Grid>
			</InexCard>
		</Grid>
	);
};

const SEPA_TEST = [
	{
		iban: "FR7630004028379876543210943",
	},
	{
		iban: "FR7630004028379876543210943",
	},
];

const DoNotRenew = ({ selectedIndex, setSelectedIndex, subscription }) => {
	useEffect(() => {
		if (subscription.typerenouv === 0) {
			setSelectedIndex(NOT_RENEW_INDEX);
		}
	}, []);
	const onClick = () => {
		setSelectedIndex(NOT_RENEW_INDEX);
	};

	const isPaiementMethodSelected = useMemo(() => {
		return selectedIndex === NOT_RENEW_INDEX;
	});

	return (
		<Grid item xs={12}>
			<InexCard
				onClick={onClick}
				selected={isPaiementMethodSelected}
				sx={{
					padding: (theme) => theme.spacing(2),
					paddingTop: (theme) => theme.spacing(2),
					paddingBottom: (theme) => theme.spacing(2),
					paddingLeft: 0,
					"&:hover": {
						cursor: "pointer",
					},
				}}
			>
				<TopBorder selected={isPaiementMethodSelected} />

				{subscription?.engagement !== undefined && subscription.engagement < 1 ? (
					<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Box display={"flex"} alignItems={"center"}>
							<PaiementMethodTitle sx={{ position: "relative", left: 35 }}>
								Vous n'êtes plus engagé(e) : Ne pas renouveller
							</PaiementMethodTitle>
						</Box>
						<Radio
							checked={isPaiementMethodSelected}
							onChange={onClick}
							value="a"
							name="radio-buttons"
							inputProps={{ "aria-label": "A" }}
							sx={{
								color: (theme) => theme.palette.secondary.main,
								"&.Mui-checked": {
									color: (theme) => theme.palette.secondary.main,
								},
							}}
						/>
					</Grid>
				) : (
					<Typography
						sx={{
							fontFamily: "Branding Medium",
							color: (theme) => theme.palette.secondary.main,
							position: "relative",
							left: 35,
						}}
					>
						Vous êtes actuellement dans vos trois mois minimum d'engagement.{" "}
					</Typography>
				)}
			</InexCard>
		</Grid>
	);
};
const AddPaiementMethod = ({ subscriptionType }) => {
	const [createSepa] = useLazyCreateSepaQuery();

	const getSepaUrl = useCallback(() => {
		createSepa({ idabo: subscriptionType }).then(({ data }) => {
			if (data) window.location.href = data.redirectFlow.redirect_url;
		});
	}, [createSepa]);

	return (
		<Grid item xs={12}>
			<InexCard
				onClick={getSepaUrl}
				sx={{
					padding: (theme) => 0,
					paddingTop: (theme) => theme.spacing(4),
					paddingBottom: (theme) => theme.spacing(4),
				}}
			>
				<Typography
					fontSize={18}
					fontFamily={"Branding Medium"}
					fontWeight={"600"}
					color={(theme) => theme.palette.secondary.main}
					sx={{ textDecoration: "underline", left: (theme) => theme.spacing(5), position: "relative" }}
				>
					Prélèvement Sepa ( ajouter votre Iban )
				</Typography>
			</InexCard>
		</Grid>
	);
};

const AbonnementPaiementList = ({ subscription, setSelectedPaiementMethod, subscriptionType }) => {
	const { data: creditCards } = useGetCreditCardsQuery({});
	const { data: sepa } = useGetSepaQuery({});
	const [selectedIndex, setSelectedIndex] = React.useState(-2);

	const _isLoading = useMemo(() => {
		return !sepa && !creditCards;
	}, [sepa, creditCards]);

	const _paiementsMethods = useMemo(() => {
		if (sepa && creditCards) {
			return sepa.concat(creditCards);
		}
	}, [sepa, creditCards]);

	const _onIndexUpdated = useCallback(
		(index) => {
			if (index === NOT_RENEW_INDEX) {
				setSelectedPaiementMethod(NOT_RENEW_INDEX);
			} else {
				setSelectedPaiementMethod(_paiementsMethods[index]);
			}
			setSelectedIndex(index);
		},
		[_paiementsMethods],
	);

	return (
		<Grid container columnSpacing={2} rowSpacing={2}>
			{_isLoading ? (
				<React.Fragment>
					{Array.from(Array(1)).map((_, index) => (
						<Skeleton
							key={index}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								marginY: (theme) => theme.spacing(1),
								marginX: (theme) => theme.spacing(2),
							}}
							variant="rectangular"
							width={"100%"}
							height={"120px"}
							animation={"pulse"}
						/>
					))}
				</React.Fragment>
			) : (
				<React.Fragment>
					{_paiementsMethods?.map((infos, index) => (
						<PaiementMethod
							key={index}
							infos={infos}
							subscription={subscription}
							index={index}
							selectedIndex={selectedIndex}
							setSelectedIndex={_onIndexUpdated}
						/>
					))}
					<DoNotRenew
						selectedIndex={selectedIndex}
						setSelectedIndex={_onIndexUpdated}
						subscription={subscription}
					/>
					{sepa?.length === 0 && <AddPaiementMethod subscriptionType={subscriptionType} />}
				</React.Fragment>
			)}
		</Grid>
	);
};

export default AbonnementPaiementList;
