import { styled, Typography } from "@mui/material";

const FormatedText = styled(Typography)(() => {
	return { color: "#ffffff", "* > *": { color: "#ffffff !important", fontFamily: "Branding Medium" } };
});

const Text = ({ block }) => {
	return <FormatedText dangerouslySetInnerHTML={{ __html: block.contenu }} />;
};

export default Text;
