import React from "react";
import { useGetOdysseeUserListQuery } from "../../store/api/odyssee";
import { ContentContainer, Title } from "../Abonnement";
import { Grid, Skeleton, styled, Typography } from "@mui/material";
import InexCard from "../../components/InexCard";
import { useNavigate } from "react-router-dom";
import withAuth from "../../HOC/withAuth";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";

const BoxTitle = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Bold",
		color: theme.palette.secondary.main,
		fontSize: 22,
		marginBottom: theme.spacing(2),
	};
});
const OdysseeList = () => {
	const user = useSelector(getUser);

	const { data: odyssees } = useGetOdysseeUserListQuery(
		{},
		{
			skip: !user,
		}
	);
	const navigate = useNavigate();

	return (
		<ContentContainer className={"content-background"} sx={{ overflow: "auto" }}>
			<Title>Mes Odyssées </Title>
			{!odyssees ? (
				<Skeleton
					width={"100%"}
					height={"200px"}
					sx={{
						backgroundColor: "white",
						boxShadow: (theme) => theme.shadows[25],
						borderRadius: "8px",
						marginTop: (theme) => theme.spacing(3),
					}}
					variant="rectangular"
					animation={"pulse"}
				/>
			) : (
				<Grid container sx={{ marginTop: (theme) => theme.spacing(2), overflow: "auto" }} spacing={3}>
					{odyssees?.map((odyssee) => (
						<Grid item xs={12} md={6} lg={4} xl={3}>
							<InexCard
								sx={{
									height: "100%",
									padding: (theme) => theme.spacing(3),
									paddingTop: (theme) => theme.spacing(3),
									paddingBottom: (theme) => theme.spacing(3),
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
								onClick={() => navigate(`/odyssee/${odyssee.id}`)}
							>
								<BoxTitle>{odyssee?.titre}</BoxTitle>
								<img
									src={`https://medias.inrees.com/img/odyssees/1_${odyssee.id}.jpg`}
									alt="flower"
									draggable="false"
								/>
							</InexCard>
						</Grid>
					))}
				</Grid>
			)}
		</ContentContainer>
	);
};

export default withAuth(OdysseeList);
