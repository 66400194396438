import React from "react";
import { GradientTypo } from "../../header/Header";
import { Box, Skeleton } from "@mui/material";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import MenuOdyssee from "./MenuOdyssee";
import ProgressOdyssee from "./ProgressOdyssee";
import InexButton from "../../InexButton";
import { ReactComponent as Ovni } from "../../../resources/images/svg/odyssee/ovni.svg";
import SummaryCarousel from "./SummaryCarousel";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";

const OdysseeHome = () => {
	const [imageLoaded, setImageLoaded] = React.useState(false);

	const { odyssee, savedStepPosition } = useOdyssee();
	const { continueOdyssee } = useOdysseeNavigation();

	return (
		<Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
			<GradientTypo sx={{ marginBottom: 5 }}>{odyssee?.titre}</GradientTypo>
			<Box sx={{ position: "relative", zIndex: 2 }}>
				<MenuOdyssee />
			</Box>
			<Box sx={{ display: "flex", position: "relative", top: -20 }}>
				<img
					onLoad={() => setImageLoaded(true)}
					src={`https://medias.inrees.com/img/odyssees/1_${odyssee?.id}.jpg`}
					style={{
						maxHeight: 650,
						opacity: +imageLoaded,
						transition: "opacity 0.3s ease",
					}}
				/>
				{/*{!imageLoaded && (*/}
				{/*	<Skeleton*/}
				{/*		width={"100%"}*/}
				{/*		height={"200px"}*/}
				{/*		sx={{*/}
				{/*			backgroundColor: "white",*/}
				{/*			boxShadow: (theme) => theme.shadows[25],*/}
				{/*			borderRadius: "8px",*/}
				{/*			marginTop: (theme) => theme.spacing(3),*/}
				{/*			maxHeight: 650,*/}
				{/*			minWidth: "50%",*/}
				{/*		}}*/}
				{/*		variant="rectangular"*/}
				{/*		animation={"pulse"}*/}
				{/*	/>*/}
				{/*)}*/}
				<Box
					sx={{
						left: -40,
						position: "relative",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: 8,
						opacity: +imageLoaded,
						transition: "opacity 0.3s ease",
					}}
				>
					<ProgressOdyssee />
					<InexButton
						startIcon={<Ovni />}
						onClick={continueOdyssee}
						variant={"text"}
						textWithGradient={false}
						text={savedStepPosition === 0 ? "Commencer l'odyssée" : "Poursuivre l'odyssée"}
						textSx={{
							color: "white",
						}}
						sx={{
							backgroundColor: "#EF7D8F",
							width: 230,
						}}
					/>
				</Box>
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
						right: 0,
						height: 50,
						background: "linear-gradient(360deg, #18122D 10.94%, rgba(24, 18, 45, 0) 75%)",
					}}
				/>
			</Box>
			<Box sx={{ width: "100%", position: "relative", top: -90 }}>
				<SummaryCarousel />
			</Box>
		</Box>
	);
};

export default OdysseeHome;
