import React from "react";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import { Box } from "@mui/material";
import InexButton from "../../InexButton";
import OdysseeProgressBar from "../OdysseeSummary/OdysseeProgressBar";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { GradientTypo } from "../../header/Header";
import { ReactComponent as Home } from "../../../resources/images/svg/odyssee/home.svg";
import StepContentContainer from "./StepContentContainer";
import StepFooter from "./StepFooter";
import { useIsMobile } from "../../../hooks/responsive";

const OdysseeStep = () => {
	const { currentStep, ressources, odyssee } = useOdyssee();
	const { navigateToMain } = useOdysseeNavigation();
	const isMobile = useIsMobile();

	return (
		<Box sx={{ paddingBottom: 10 }}>
			<Box
				sx={{
					position: "absolute",
					top: "0px",
					right: "50%",
					bottom: 0,
					zIndex: 1,
					transform: "translateX(50%)",
				}}
			>
				<img
					src={`https://medias.inrees.com/img/odyssees/1_${odyssee?.id}.jpg`}
					style={{ position: "relative", zIndex: 2, width: "100%", opacity: 0.3 }}
				/>
			</Box>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					position: "relative",
					zIndex: 2,
					flexDirection: isMobile ? "column" : "row",
				}}
				ml={"3vw"}
				mr={"3vw"}
				gap={isMobile ? 3 : 0}
			>
				<InexButton
					onClick={navigateToMain}
					variant={"text"}
					textWithGradient={false}
					text={"Mon odyssée"}
					startIcon={<Home />}
					textSx={{
						color: "#ffffff",
					}}
					sx={{
						backgroundColor: "#FFFFFF33",
						width: 210,
					}}
				/>

				<Box ml={"3vw"} mr={"6vw"} sx={{ width: "100%" }}>
					<OdysseeProgressBar />
				</Box>
			</Box>
			<Box
				sx={{ display: "flex", width: "100%", justifyContent: "center", position: "relative", zIndex: 2 }}
				mt={2}
				px={2}
			>
				<GradientTypo fontSize={isMobile ? "26px" : "40px"} textAlign={"center"} sx={{ marginBottom: 5 }}>
					{currentStep?.titre}
				</GradientTypo>
			</Box>
			<Box sx={{ position: "relative", zIndex: 2 }} mb={4}>
				<StepContentContainer currentStep={currentStep} ressources={ressources} />
			</Box>
			<Box sx={{ position: "relative", zIndex: 2 }}>
				<StepFooter />
			</Box>
		</Box>
	);
};

export default OdysseeStep;
