import React from "react";
import Header from "../components/header/Header";
import { Outlet } from "react-router";
import { Grid } from "@mui/material";
import { SideBar } from "../routes/RoutesDefinitions";
import { useIsMobile } from "../hooks/responsive";
import Footer from "../components/footer/Footer";

const MainLayout = () => {
	const isMobile = useIsMobile();

	return (
		<div>
			<Outlet />
			<Footer />
		</div>
	);
};

export default MainLayout;
