import React from "react";
import { Box, Fade, styled, Typography } from "@mui/material";
import Image from "../OdysseeBlocs/Image";
import Video from "../OdysseeBlocs/Video";
import Text from "../OdysseeBlocs/Text";
import WebContent from "../OdysseeBlocs/WebContent/index";
import Podcast from "../OdysseeBlocs/Podcast";
import Pdf from "../OdysseeBlocs/Pdf";
import { ReactComponent as Clock } from "../../../resources/images/svg/odyssee/clock.svg";

const Container = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		padding: 20,
		marginLeft: "3vw",
		marginRight: "3vw",
		borderRadius: "20px",
	};
});

const RenderContent = ({ bloc }) => {
	const renderBlock = () => {
		switch (bloc?.categorie) {
			case "image":
				return <Image block={bloc} />;
			case "video":
				return <Video block={bloc} />;
			case "text":
				return <Text block={bloc} />;
			case "web":
				return <WebContent block={bloc} />;
			case "podcast":
				return <Podcast block={bloc} />;
			case "pdf":
				return <Pdf block={bloc} />;
			default:
				return <Image block={bloc} />;
		}
	};

	return renderBlock();
};

const StepContentContainer = ({ currentStep, ressources }) => {
	return (
		<Container>
			<Fade in={!!currentStep}>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }} mb={2}>
					<Clock stroke={"rgba(255,255,255,0.5)"} />
					<Typography color={"rgba(255,255,255,0.5)"} fontSize={14} fontFamily={"Branding SemiBold"}>{`${
						currentStep?.type === "Recommandé" ? "Recommandé" : "Optionnel"
					} ${currentStep?.minutes} min`}</Typography>
				</Box>
			</Fade>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
				{ressources?.map((bloc) => (
					<RenderContent key={bloc.id} bloc={bloc} />
				))}
			</Box>
		</Container>
	);
};

export default StepContentContainer;
