import AudioPlayer from "react-h5-audio-player";
import "../../../resources/scss/audioPlayer/reactAudioPlayer.scss";
import { Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import { computeHexOpacity } from "../../../utils/hexaOpacity";

const Podcast = ({ block }) => {
	return (
		<Box sx={{ backgroundColor: computeHexOpacity(blue[50], 15), borderRadius: "10px", paddingTop: 1 }}>
			<div className="layoutRoot">
				<AudioPlayer
					autoPlay={false}
					src={block.source}
					onPlay={(e) => console.log("onPlay")}
					customAdditionalControls={[]}
				/>
			</div>
		</Box>
	);
};

export default Podcast;
