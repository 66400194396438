import React from "react";
import { Box, styled, Typography } from "@mui/material";

const ITEMS = ["Mon odyssée", "Mes étapes", "Communauté", "Aller plus loin"];

const ITEM_WIDTH = 200;

const Container = styled(Box)(() => {
	return {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#FFFFFE1A",
		borderRadius: 40,
		position: "relative",
		cursor: "pointer",
	};
});

const Item = styled(Box)(() => {
	return {
		width: 200,
		height: 57,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
	};
});

const Indicator = styled(Box)(() => {
	return {
		width: 200,
		height: 57,
		position: "absolute",
		borderRadius: 40,
		background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
		transition: "transform 0.3s ease",
	};
});

const MenuOdyssee = () => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const onItemClicked = (index) => {
		setSelectedIndex(index);
	};

	return (
		<Container>
			<Indicator sx={{ transform: `translateX(${selectedIndex * ITEM_WIDTH}px)` }} />
			{ITEMS.map((item, index) => (
				<Item key={item} onClick={() => onItemClicked(index)}>
					<Typography
						sx={{ position: "relative", zIndex: 3, transition: "color 0.3s ease", userSelect: "none" }}
						fontFamily={"Branding SemiBold"}
						fontSize={18}
						color={index === selectedIndex ? "#182B43" : "#FFFFFF"}
					>
						{item}
					</Typography>
				</Item>
			))}
		</Container>
	);
};

export default MenuOdyssee;
