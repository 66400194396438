import React from "react";
import { Box, Typography } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useOdyssee from "../../../context/odyssee/useOdyssee";

const GradientSVG = ({ startColor, endColor, idCSS, rotation = "0deg" }) => {
	let gradientTransform = `rotate(${rotation})`;

	return (
		<svg style={{ height: 0 }}>
			<defs>
				<linearGradient id={idCSS} gradientTransform={gradientTransform}>
					<stop offset="0%" stopColor={startColor} />
					<stop offset="100%" stopColor={endColor} />
				</linearGradient>
			</defs>
		</svg>
	);
};

const ProgressOdyssee = () => {
	const { modules, progressModuleIndex, progressStepIndexInModule } = useOdyssee();

	const { totalSteps, currentStep, progress } = React.useMemo(() => {
		if (!modules) return { totalSteps: 0, currentStep: 0, progress: 0 };
		let { totalSteps, currentStep } = modules?.reduce(
			(acc, curr, currIndex) => {
				if (currIndex === progressModuleIndex) {
					acc.currentStep = acc.totalSteps + progressStepIndexInModule;
				}
				acc.totalSteps += curr.lecon.length;
				return acc;
			},
			{ totalSteps: 0, currentStep: 0 }
		);
		return { progress: (currentStep * 100) / totalSteps, totalSteps, currentStep };
	}, [modules, progressStepIndexInModule, progressModuleIndex]);

	return (
		<>
			<GradientSVG startColor={"#EE7992"} endColor={"#F4C54D"} idCSS={"odyssee-circle"} />
			<CircularProgressbarWithChildren
				value={progress}
				strokeWidth={5}
				styles={{
					// Customize the root svg element
					root: {},
					// Customize the path, i.e. the "completed progress"
					path: {
						// Path color
						// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
					},
				}}
			>
				{/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
				<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
					<Typography color={"#FFFFFF"} fontFamily={"Branding Medium"}>
						Vous avez réalisé
					</Typography>
					<Typography color={"#FFFFFF"} fontFamily={"Branding Bold"} fontSize={40}>
						{Math.round(progress)}%
					</Typography>
					<Typography color={"#FFFFFF"} fontFamily={"Branding Medium"}>
						de votre Odyssée
					</Typography>
					<Typography color={"rgba(255,255,255,0.56)"} fontFamily={"Branding Medium"}>
						{currentStep} étapes sur {totalSteps}
					</Typography>
				</Box>
			</CircularProgressbarWithChildren>
		</>
	);
};

export default ProgressOdyssee;
