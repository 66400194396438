import React from "react";
import { Box, styled, Typography } from "@mui/material";

const BackgroundImage = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
}));

const Video = ({ webContent }) => {
	return (
		<Box sx={{ backgroundColor: "transparent", display: "flex", alignItems: "center", flexDirection: "column" }}>
			<div
				style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				dangerouslySetInnerHTML={{ __html: webContent?.iframe }}
			/>
			<Typography mt={5} color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={40} textTransform={"uppercase"}>
				{webContent.titre}
			</Typography>
		</Box>
	);
};

export default Video;
