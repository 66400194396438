import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "auth",
	initialState: { inreesId: null, inreesToken: null, showSnackbar: false, authToken: null, user: null },
	reducers: {
		setInreesId(state, action) {
			state.inreesId = action.payload;
		},
		setInreesToken(state, action) {
			state.inreesToken = action.payload;
		},
		setShowSnackbar(state, action) {
			state.showSnackbar = action.payload;
		},
		setAuthToken(state, action) {
			state.authToken = action.payload;
		},
		setUser(state, action) {
			state.user = action.payload;
		},
	},
});

export default slice.reducer;
export const { setInreesId, setInreesToken, setShowSnackbar, setAuthToken, setUser } = slice.actions;

export const getInreesId = (state) => state.auth.inreesId;
export const getInreesToken = (state) => state.auth.inreesToken;
export const getShowSnackbar = (state) => state.auth.showSnackbar;
export const getAuthToken = (state) => state?.auth?.authToken || window.localStorage.getItem("token");
export const getUser = (state) => state.auth.user;
