import React from "react";
import OdysseeContextProvider from "../../context/odyssee";
import MinimalistHeader from "../../components/header/MinimalistHeader";
import withAuth from "../../HOC/withAuth";
import OdysseeHome from "../../components/odysee/OdysseeHome";
import OdysseeSummary from "../../components/odysee/OdysseeSummary";
import OdysseeStep from "../../components/odysee/OdysseeStep";

import { Box } from "@mui/material";
import OdysseeNavigationContextProvider, { ODYSSEE_PAGES } from "../../context/odysseeNavigation";
import useOdysseeNavigation from "../../context/odysseeNavigation/useOdyssee";
import useTabTitle from "../../hooks/useTabTitle";
import useOdyssee from "../../context/odyssee/useOdyssee";

const Odyssee = () => {
	const { currentPage, navigateToSummary } = useOdysseeNavigation();
	const { odyssee } = useOdyssee();

	useTabTitle({ customTitle: `Votre odyssée - ${odyssee?.titre || ""}` });
	return (
		<Box sx={{ background: "#18122D", position: "relative" }}>
			<MinimalistHeader />
			{currentPage === ODYSSEE_PAGES.Main && <OdysseeHome />}
			{currentPage === ODYSSEE_PAGES.Summary && <OdysseeSummary />}
			{currentPage === ODYSSEE_PAGES.Step && <OdysseeStep />}
		</Box>
	);
};

const OdysseeManager = () => {
	return (
		<OdysseeContextProvider>
			<OdysseeNavigationContextProvider>
				<Odyssee />
			</OdysseeNavigationContextProvider>
		</OdysseeContextProvider>
	);
};

export default withAuth(OdysseeManager);
