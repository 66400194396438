import { api } from "./rtkApi";
import { store } from "../store";
import { encode } from "querystring";

export const odysseeApi = api.injectEndpoints({
	endpoints: (build) => ({
		getOdysseeUserList: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;

				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/list`,
				};
			},
		}),
		getOdyssee: build.query({
			query: ({ idOdyssee, authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/${idOdyssee}`,
				};
			},
		}),
		getOdysseeModules: build.query({
			query: ({ idOdyssee, authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/modules/${idOdyssee}`,
				};
			},
		}),
		getOdysseeRessources: build.query({
			query: ({ stepId, authToken }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/ressources/${stepId}`,
				};
			},
		}),
		updateOdysseeStep: build.mutation({
			query: ({ odysseeUserId, authToken, etapeId }) => {
				return {
					method: "POST",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/updateOdysseeStep/${odysseeUserId}`,
					body: encode({ etapeId }),
				};
			},
		}),
		getOdysseeWebRessource: build.query({
			query: ({ odysseeId, authToken, categorie, ressourceId }) => {
				return {
					method: "GET",
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					url: `/odyssee/webContent/${odysseeId}?categorie=${categorie}&ressourceId=${ressourceId}`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetOdysseeUserListQuery,
	useGetOdysseeQuery,
	useGetOdysseeModulesQuery,
	useGetOdysseeRessourcesQuery,
	useUpdateOdysseeStepMutation,
	useGetOdysseeWebRessourceQuery,
} = odysseeApi;
