import React from "react";
import { Box, styled, Typography } from "@mui/material";

const BackgroundImage = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
}));
const BackgroundOpacity = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
	background: "#00000088",
}));

const Collection = ({ webContent }) => {
	return (
		<Box sx={{ backgroundColor: "#182B43" }}>
			<BackgroundImage style={{ backgroundImage: `url(${webContent.image})` }}></BackgroundImage>
			<BackgroundOpacity></BackgroundOpacity>
			<Box
				sx={{
					display: "flex",
					gap: 4,
					alignItems: "stretch",
					zIndex: 2,
					position: "relative",
					padding: 5,
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				{webContent?.livres?.map((livre) => (
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 190 }}>
						<img src={livre?.image} style={{ height: 300, width: 190, objectFit: "contain" }} />
						<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"}>
							{livre?.titre}
						</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default Collection;
