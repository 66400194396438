import React from "react";
import { Box, Grid, IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";
import logo from "../../resources/images/svg/logo.svg";
import userLogo from "../../resources/images/svg/user.svg";
import { useIsMobile } from "../../hooks/responsive";
import { getLoginRedirectURL, useIsLoggedIn } from "../../hooks/auth";
import SuccessfulConnexionSnackbar from "./SuccessfulConnexionSnackbar";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import burgerMenu from "../../resources/images/svg/burger_menu.svg";
import MobileMenu from "./MobileMenu";
import Universe from "./Universe";
import { useNavigate } from "react-router-dom";

const Container = styled(Grid)(({ theme }) => {
	return {
		width: "100%",
		position: "relative",
		display: "flex",
		zIndex: theme.zIndex.appBar,
	};
});

export const UserInitialsContainer = styled(Box)(({ theme }) => {
	return {
		backgroundColor: "rgba(36,38,56,0.53)",
		borderRadius: "50%",
		height: 42,
		width: 42,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: theme.transitions.create(["box-shadow"]),
		"&:hover": {
			boxShadow: "0 0 15px 0 rgb(36 38 56 / 53%)",
		},
	};
});

const Header = () => {
	const isMobile = useIsMobile();
	const user = useSelector(getUser);
	const navigate = useNavigate();

	const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

	const isLoggedIn = useIsLoggedIn();

	const redirectUrl = getLoginRedirectURL();

	const navigateToHome = () => navigate("/");

	return (
		<>
			<MobileMenu isOpen={mobileMenuOpen} close={() => setMobileMenuOpen(false)} />
			<Container container px={isMobile ? 2 : 5} py={5}>
				<Grid container direction={"column"}>
					<Grid container direction={"row"} justifyContent={"space-between"}>
						<Grid
							item
							justifyContent={"flex-start"}
							alignItems={"center"}
							display={"flex"}
							onClick={navigateToHome}
							sx={{ cursor: "pointer" }}
						>
							<img src={logo} className="" alt="logo" style={{}} />
						</Grid>
						{!isMobile ? (
							<Grid item display={"flex"} alignItems={"center"} gap={4}>
								<Universe />
								{!isLoggedIn ? (
									<a href={redirectUrl}>
										<IconButton sx={{ width: 40, height: 40 }} size={"medium"}>
											<img src={userLogo} alt={"user-logo"} />
										</IconButton>
									</a>
								) : (
									<UserInitialsContainer>
										{!!user && (
											<Typography color={"white"} fontFamily={"Branding Bold"}>
												{`${user?.prenom[0]?.toUpperCase()}${user?.nom[0]?.toUpperCase()}`}
											</Typography>
										)}
									</UserInitialsContainer>
								)}
							</Grid>
						) : (
							<IconButton sx={{ bottom: "6px" }} onClick={() => setMobileMenuOpen(true)}>
								<img style={{ width: "18px" }} src={burgerMenu} alt={"burger-menu"} />
							</IconButton>
						)}
					</Grid>
				</Grid>
				<SuccessfulConnexionSnackbar />
			</Container>
		</>
	);
};

export default Header;
