import React from "react";
import Header, { BackgroundBox } from "../components/header/Header";
import { Outlet } from "react-router";
import { Grid } from "@mui/material";
import { SideBar } from "../routes/RoutesDefinitions";
import { useIsMobile } from "../hooks/responsive";
import Footer from "../components/footer/Footer";
import FloralBg from "../resources/images/svg/FloralPage@1,5x.svg";

const MainLayout = () => {
	const isMobile = useIsMobile();

	return (
		<>
			<BackgroundBox>
				<img
					src={FloralBg}
					className=""
					alt="header-floral-bg"
					style={{
						float: "left",
					}}
				/>
			</BackgroundBox>
			<div>
				<Header />
				<Grid container spacing={2} sx={{ paddingX: isMobile ? 2 : 3, flexWrap: "nowrap", marginBottom: 14 }}>
					{!isMobile && (
						<Grid
							item
							xs={3}
							lg={2}
							sx={{ minWidth: 250, maxWidth: 300, marginBottom: (theme) => theme.spacing(5) }}
						>
							<SideBar />
						</Grid>
					)}
					<Grid item xs lg={10} sx={{ marginBottom: (theme) => theme.spacing(4), overflowX: "auto" }}>
						<Outlet />
					</Grid>
				</Grid>
				<Footer />
			</div>
		</>
	);
};

export default MainLayout;
