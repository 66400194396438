import React from "react";
import { Box, styled, Typography } from "@mui/material";

const BackgroundImage = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
}));

const Article = ({ webContent }) => {
	return (
		<Box sx={{ backgroundColor: "rgba(24,18,45,0.95)" }}>
			<Box sx={{ display: "flex", gap: 3, alignItems: "stretch", zIndex: 2, position: "relative", padding: 5 }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
						flexDirection: "column",
						gap: 3,
					}}
				>
					<Typography
						dangerouslySetInnerHTML={{ __html: webContent?.titre }}
						textAlign={"center"}
						color={"#ffffff"}
						fontFamily={"Branding Bold"}
						fontSize={30}
					></Typography>
					<Typography
						dangerouslySetInnerHTML={{ __html: webContent?.contenu }}
						color={"#ffffff"}
						fontFamily={"Branding SemiBold"}
					></Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default Article;
