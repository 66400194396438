import React from "react";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const Image = ({ block }) => {
	const divRef = React.useRef(null);
	const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });

	const meta = React.useMemo(() => {
		if (block?.meta) {
			return JSON.parse(block.meta);
		}
	}, [block?.meta]);

	React.useEffect(() => {
		if (divRef.current?.offsetWidth) {
			const width = (Math.max(meta?.percent, 5) / 100) * divRef.current?.offsetWidth;
			const height = width * meta?.ratio;
			setDimensions({ height, width });
		}
	}, [divRef.current, meta]);

	return (
		<div ref={divRef}>
			<img
				src={block.source}
				alt="Resizable image"
				style={{ width: dimensions.width || 0, height: dimensions.height || 0 }}
			/>
			<Typography fontSize={13} color={grey[500]}>
				{block?.contenu}
			</Typography>
		</div>
	);
};

export default Image;
