import React from "react";
import OdysseeProgressBar from "./OdysseeProgressBar";
import { Box, Fade, styled } from "@mui/material";
import { ReactComponent as Home } from "../../../resources/images/svg/odyssee/home.svg";
import { GradientTypo } from "../../header/Header";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import SummaryCard from "./SummaryCard";
import SummarySteps from "./SummarySteps";
import InexButton from "../../InexButton";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";

const OdysseeSummary = () => {
	const { odyssee, currentModule } = useOdyssee();
	const { navigateToMain } = useOdysseeNavigation();

	const devUrl = React.useMemo(() => {
		if (window.location.hostname === "localhost") {
			// return "dev/";
			return "";
		}
		return "";
	}, []);

	return (
		<Box>
			<Box sx={{ display: "flex", alignItems: "center", position: "relative", zIndex: 2 }} ml={"3vw"} mr={"3vw"}>
				<InexButton
					onClick={navigateToMain}
					variant={"text"}
					textWithGradient={false}
					text={"Mon odyssée"}
					startIcon={<Home />}
					textSx={{
						color: "#ffffff",
					}}
					sx={{
						backgroundColor: "#FFFFFF33",
						width: 210,
					}}
				/>

				<Box ml={"3vw"} mr={"6vw"} sx={{ width: "100%" }}>
					<OdysseeProgressBar />
				</Box>
			</Box>
			<Box
				sx={{ display: "flex", width: "100%", justifyContent: "center", position: "relative", zIndex: 2 }}
				mt={2}
			>
				<GradientTypo textAlign={"center"} sx={{ marginBottom: 5 }}>
					{currentModule?.titre}
				</GradientTypo>
			</Box>
			<Box
				sx={{ display: "flex", gap: 5, justifyContent: "center", alignItems: "center", position: "relative" }}
				mb={7}
			>
				<Box sx={{ position: "absolute", top: "-40%", right: "30%", bottom: 0, zIndex: 1 }}>
					<Fade in={!!odyssee?.id}>
						<img
							src={`https://medias.inrees.com/img/odyssees/1_${odyssee?.id}.jpg`}
							style={{
								position: "relative",
								zIndex: 2,
								width: "100%",
								filter: "blur(20px)",
								opacity: 0.6,
							}}
						/>
					</Fade>
				</Box>
				<Fade in={!!currentModule?.id}>
					<Box sx={{ position: "relative", width: "580px", zIndex: 2 }}>
						<Box
							sx={{
								backgroundColor: "rgba(255,255,255,0.47)",
								position: "absolute",
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								filter: "blur(20px)",
							}}
						/>
						<Box sx={{ backgroundColor: "#27213B", padding: 2, position: "relative", zIndex: 2 }}>
							<img
								src={`https://medias.inrees.com/img/odyssees/${devUrl}modules/1_${currentModule?.id}.jpg`}
								style={{ maxHeight: 400, position: "relative", zIndex: 2, width: "100%" }}
							/>
						</Box>
					</Box>
				</Fade>
				<Fade in={!!currentModule?.id}>
					<Box sx={{ position: "relative", zIndex: 2 }}>
						<SummaryCard />
					</Box>
				</Fade>
			</Box>
			<Box sx={{ paddingBottom: 10 }}>
				<SummarySteps steps={currentModule?.lecon} />
			</Box>
		</Box>
	);
};

export default OdysseeSummary;
