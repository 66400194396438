import React, { useRef, useState } from "react";
import { Box, Button, Fade, styled, Typography } from "@mui/material";
import AudioPlayer from "react-h5-audio-player";

const BackgroundImage = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
}));

const Podcast = ({ webContent }) => {
	return (
		<Box
			sx={{
				backgroundColor: "rgba(24,18,45,0.95)",
				minHeight: 500,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<BackgroundImage style={{ backgroundImage: `url(${webContent.imageHome})` }}></BackgroundImage>
			<Box sx={{ padding: 5, zIndex: 2, position: "relative", width: "90%" }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
						flexDirection: "column",
						gap: 3,
						alignItems: "center",
						background: "rgba(32,51,73,.6)",
						padding: 5,
					}}
				>
					<Typography textAlign={"center"} color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={30}>
						{webContent?.titre}
					</Typography>

					<AudioPlayer
						autoPlay={false}
						src={webContent?.mp3}
						onPlay={(e) => console.log("onPlay")}
						customAdditionalControls={[]}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Podcast;
