import React from "react";
import { Box, styled, Typography } from "@mui/material";
import InexButton from "../../InexButton";
import Checkbox from "@mui/material/Checkbox";
import { useUpdateOdysseeStepMutation } from "../../../store/api/odyssee";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { useIsMobile } from "../../../hooks/responsive";

const Container = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		padding: 20,
		marginLeft: "3vw",
		marginRight: "3vw",
		borderRadius: "20px",
	};
});

const CustomCheckBox = styled(Checkbox)(() => {
	return {
		color: "#ffffff",
		"&.Mui-checked": {
			color: "#ffffff",
		},
	};
});

const StepFooter = () => {
	const [checked, setChecked] = React.useState(true);
	const { navigateToNextStep, navigateToPrevStep, isFirstStep, isLastStep } = useOdysseeNavigation();
	const isMobile = useIsMobile();

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	if (isMobile) {
		return (
			<Container>
				<Typography fontFamily={"Branding Bold"} color={"#ffffff"} fontSize={20} mb={2}>
					Vous avez fait un pas de plus dans votre odyssée
				</Typography>
				<Typography fontFamily={"Branding Medium"} color={"#ffffff"} fontSize={15} mb={5}>
					Le géant de fer: comment ne pas tomber sous le charme de cette aventure qui relate la complicité
					d'un enfant avec un robot géant tombé du ciel ?
				</Typography>
				<Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
					<Box sx={{ display: "flex", alignItems: "center" }} mb={2}>
						<CustomCheckBox onChange={handleChange} checked={checked} />
						<Typography fontFamily={"Branding Medium"} color={"#ffffff"} fontSize={15}>
							Je veux découvrir la suite et poursuivre mon odyssée
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: 1,
							width: "100%",
							justifyContent: "space-between",
						}}
					>
						<InexButton
							sx={{ backgroundColor: "#C7C4DE", width: 100, opacity: isFirstStep ? 0.4 : 1 }}
							textWithGradient={false}
							text={"Retour"}
							onClick={() => {
								setChecked(true);
								navigateToPrevStep();
							}}
							textSx={{ color: "#182B43" }}
							disabled={isFirstStep}
						/>
						<InexButton
							sx={{
								backgroundColor: "#EF7D8F",
								width: 220,
								opacity: checked && !isLastStep ? 1 : 0.3,
								transition: "opacity 0.3s ease",
							}}
							onClick={() => {
								setChecked(true);
								navigateToNextStep();
							}}
							textWithGradient={false}
							text={"Découvrir l’étape suivante"}
							textSx={{ color: "#FFFFFF", background: "transparent" }}
							disabled={isLastStep}
						/>
					</Box>
				</Box>
			</Container>
		);
	}

	return (
		<Container>
			<Typography fontFamily={"Branding Bold"} color={"#ffffff"} fontSize={20} mb={2}>
				Vous avez fait un pas de plus dans votre odyssée
			</Typography>
			<Typography fontFamily={"Branding Medium"} color={"#ffffff"} fontSize={15} mb={5}>
				Le géant de fer: comment ne pas tomber sous le charme de cette aventure qui relate la complicité d'un
				enfant avec un robot géant tombé du ciel ?
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
				<InexButton
					sx={{ backgroundColor: "#C7C4DE", width: 100, opacity: isFirstStep ? 0.4 : 1 }}
					textWithGradient={false}
					text={"Retour"}
					onClick={() => {
						setChecked(true);
						navigateToPrevStep();
					}}
					textSx={{ color: "#182B43" }}
					disabled={isFirstStep}
				/>
				<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<CustomCheckBox onChange={handleChange} checked={checked} />
						<Typography fontFamily={"Branding Medium"} color={"#ffffff"} fontSize={15}>
							Je veux découvrir la suite et poursuivre mon odyssée
						</Typography>
					</Box>
					<InexButton
						sx={{
							backgroundColor: "#EF7D8F",
							width: 250,
							opacity: checked && !isLastStep ? 1 : 0.3,
							transition: "opacity 0.3s ease",
						}}
						onClick={() => {
							setChecked(true);
							navigateToNextStep();
						}}
						textWithGradient={false}
						text={"Découvrir l’étape suivante"}
						textSx={{ color: "#FFFFFF", background: "transparent" }}
						disabled={isLastStep}
					/>
				</Box>
			</Box>
		</Container>
	);
};

export default StepFooter;
