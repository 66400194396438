import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { ReactComponent as Clock } from "../../../resources/images/svg/odyssee/clock.svg";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import moment from "moment";

const Card = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		borderRadius: 20,
		position: "absolute",
		backdropFilter: "blur(9px)",
	};
});

const SummaryCard = () => {
	const { odyssee, currentModule, ...rest } = useOdyssee();

	const totalDuration = React.useMemo(() => {
		return currentModule?.lecon?.reduce(
			(acc, value) => {
				if (!value.id) return acc;
				if (value.type === "Recommandé") {
					acc.recommended += value?.minutes || 0;
				} else {
					acc.optional += value?.minutes || 0;
				}
				return acc;
			},
			{ recommended: 0, optional: 0 }
		);
	}, [currentModule]);

	const recommendedTime = React.useMemo(() => {
		const duration = moment.duration(totalDuration?.recommended, "minutes");

		return duration.asMinutes() < 60
			? `${duration.minutes()} min`
			: `${Math.floor(duration.asHours())}h${duration.minutes()}`;
	}, [totalDuration]);

	const totalTime = React.useMemo(() => {
		const duration = moment.duration(totalDuration?.recommended + totalDuration?.optional, "minutes");

		return duration.asMinutes() < 60
			? `${duration.minutes()} min`
			: `${Math.floor(duration.asHours())}h${duration.minutes()}`;
	}, [totalDuration]);

	return (
		<Box sx={{ position: "relative", width: 600 }}>
			<Card sx={{ width: 600, height: "calc(100% - 100px)", transform: "translate(0px, 50px)" }}></Card>
			<Card sx={{ width: 550, height: "calc(100% - 50px)", transform: "translate(25px, 25px)" }}></Card>
			<Card sx={{ width: 500, height: "auto", transform: "translate(50px, 0px)", position: "relative" }} p={4}>
				<Box
					sx={{
						background: "#FFFFFF1A",
						display: "flex",
						borderRadius: "50px",
						padding: "10px 15px",
						gap: 1,
						width: "fit-content",
					}}
				>
					{/*<Typography fontFamily={"Branding Medium"} color={"#FFFFFF"}>*/}
					{/*	Module A*/}
					{/*</Typography>*/}
					<Clock stroke={"white"} />
					<Typography fontFamily={"Branding Medium"} color={"#FFFFFF"}>
						Entre {recommendedTime} et {totalTime}
					</Typography>
				</Box>
				<Typography
					color={"#FFFFFF"}
					fontFamily={"branding Medium"}
					fontSize={15}
					mt={2}
					dangerouslySetInnerHTML={{ __html: currentModule?.description }}
				/>
			</Card>
		</Box>
	);
};

export default SummaryCard;
