import React from "react";
import { Box, Fade, styled } from "@mui/material";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { ODYSSEE_PAGES } from "../../../context/odysseeNavigation";

const Container = styled(Box)(() => {
	return {
		// backgroundColor: "#FFFFFE1A",
		backgroundColor: "#FFFFFE1A",
		height: 20,
		width: "100%",
		position: "relative",
		borderRadius: 30,
	};
});

const Step = styled(Box)(() => {
	return {
		height: 20,
		width: 20,
		borderRadius: "50%",
		backgroundColor: "rgba(255,255,255,0.42)",
		position: "absolute",
		top: 0,
	};
});

const IndicatorBar = styled(Box)(() => {
	return {
		background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
		height: 20,
		position: "absolute",
		top: 0,
		left: 0,
		borderRadius: 30,
		transition: "width 0.3s ease",
	};
});
const SummaryBar = styled(Box)(() => {
	return {
		background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
		height: 20,
		position: "absolute",
		top: 0,
		left: 0,
		borderRadius: 30,
		transition: "width 0.3s ease",
		animation: "blinker 3s linear infinite",
		"@keyframes blinker": {
			"50%": {
				opacity: 0.2,
			},
		},
	};
});

const IndicatorDot = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF",
		height: 20,
		width: 20,
		borderRadius: "50%",
		position: "absolute",
		top: 0,
		transition: "left 0.3s ease",
	};
});
const IndicatorDotBlur = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF",
		height: 20,
		width: 20,
		borderRadius: "50%",
		position: "absolute",
		top: 0,
		transition: "left 0.3s ease",
		filter: "blur(10px)",
	};
});

const OdysseeProgressBar = ({ progress = 14 }) => {
	const containerRef = React.useRef(null);
	const [containerWidth, setContainerWidth] = React.useState(0);
	const { width } = useWindowDimensions();
	const { modules, progressModuleIndex, progressStepIndexInModule } = useOdyssee();
	const { currentPage } = useOdysseeNavigation();

	const Steps = React.useMemo(() => {
		return modules?.map((item) => item?.lecon?.length) || [];
	}, [modules]);

	React.useEffect(() => {
		const updateWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateWidth();
		window.addEventListener("resize", updateWidth);
		return () => {
			window.removeEventListener("resize", updateWidth);
		};
	}, []);

	const stepWidth = React.useMemo(() => {
		if (!containerWidth) return 0;
		return containerWidth / Steps.length;
	}, [containerWidth, Steps]);

	const progressPosition = React.useMemo(() => {
		if (!stepWidth) return 0;
		const offset = (progressStepIndexInModule * stepWidth) / Steps[progressModuleIndex];
		return offset + progressModuleIndex * stepWidth;
	}, [progress, stepWidth, progressModuleIndex, progressStepIndexInModule, Steps]);

	return (
		<Fade in={Steps.length > 0}>
			<Container ref={containerRef}>
				{currentPage === ODYSSEE_PAGES.Summary ? (
					<SummaryBar sx={{ width: stepWidth + 20, left: progressModuleIndex * stepWidth }}></SummaryBar>
				) : (
					<>
						<IndicatorBar sx={{ width: progressPosition + 20 }} />
						<IndicatorDot sx={{ left: progressPosition }}>
							<IndicatorDotBlur />
						</IndicatorDot>
					</>
				)}

				{Steps.map((step, index) => (
					<Box key={index}>
						<Step sx={{ left: index * stepWidth }} />
					</Box>
				))}
				{/*<Step sx={{ right: 0 }} />*/}
			</Container>
		</Fade>
	);
};

export default OdysseeProgressBar;
